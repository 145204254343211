import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { hashUrl } from "url"

const baseUrl = hashUrl

export const restaurantsHashApi = createApi({
  reducerPath: "restaurantsHashApi",
  tagTypes: ["Restaurants"],
  // baseQuery: fetchBaseQuery({ baseUrl }),
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Navbat-key", `kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr`)
      //  headers.set('Navbat-key', `YpCCMBnHLsK0bz6agS7lEn4UMfKQTA8jOJjNLk38`);

      return headers
    },
  }),
  endpoints: (builder) => ({
    getReastaurantByHash: builder.query({
      query: (hash) => `client/v1/bookings/${hash}`,
    }),

    updateRestaurant: builder.mutation({
      query: ({ body, bookingId, hash }) => ({
        url: `v1/master/booking/update-with-hash/${bookingId}/${hash}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
})


export const { useGetReastaurantByHashQuery, useUpdateRestaurantMutation } = restaurantsHashApi;
